<h2 class="section-title">
  {{ 'DREAMIX.SUBMIT_REPORT.SUBMIT_REPORT' | translate }}
</h2>
<a
  href="/templates/time_log_report_template.xlsx"
  download="time_log_report_template.xlsx"
  target="blank"
>
  <button
    matTooltip="Download sample timesheet"
    matTooltipPosition="right"
    mat-raised-button
    type="button"
    color="primary"
  >
    <mat-icon>download</mat-icon>
    <span>{{ 'ADMIN.CLIENT_HOURS.DOWNLOAD_SAMPLE_TIMESHEET' | translate }}</span>
  </button>
</a>

<div class="submit-report-container">
  <form [formGroup]="reportForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
    <div class="form-group">
      <mat-form-field (click)="picker1.open()" color="accent" appearance="fill">
        <mat-label>{{ 'ADMIN.CLIENT_HOURS.MONTH' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker1"
          formControlName="dateControl"
          [min]="minDate"
          [max]="maxDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker
          #picker1
          startView="multi-year"
          color="primary"
          (monthSelected)="setMonthAndYear($event, picker1)"
        ></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="form-item-full no-padding" subscriptSizing="dynamic">
        <mat-label>{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'ADMIN.CLIENT_HOURS.PROJECTS' | translate }}"
          #filter
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let project of projects | filter: filter.value : 'name'"
            [value]="project"
          >
            {{ project.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="reportForm.get('project').invalid && reportForm.get('project').touched">
          {{ 'DREAMIX.SUBMIT_REPORT.PROJECT_REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>
      <app-file-upload-button
        [control]="reportForm.get('multipartFile')"
        (fileSelected)="onFileSelected($event)"
        [acceptedTypes]="acceptedTypes"
      >
      </app-file-upload-button>
    </div>
    <div>
      <button mat-raised-button color="primary" type="submit" [disabled]="reportForm.invalid">
        {{ 'SHARED.SUBMIT' | translate }}
      </button>
    </div>
  </form>
</div>
