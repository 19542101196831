import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPage } from '../models/page/page.model';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private urlBase: string = 'api/pages';

  constructor(private httpClient: HttpClient) {}

  getAllPages(): Observable<IPage[]> {
    return this.httpClient.get<IPage[]>(this.urlBase);
  }

  createPage(page: IPage): Observable<HttpResponse<IPage>> {
    return this.httpClient.post<IPage>(`${this.urlBase}`, page, {
      observe: 'response',
    });
  }

  updatePage(page: IPage): Observable<IPage> {
    return this.httpClient.put<IPage>(`${this.urlBase}`, page);
  }

  deletePageById(id: number): Observable<IPage> {
    return this.httpClient.delete<IPage>(`${this.urlBase}/${id}`);
  }

  getPageById(id: number): Observable<IPage> {
    return this.httpClient.get<IPage>(`${this.urlBase}/${id}`);
  }

  getPageBySlug(slug: string): Observable<IPage> {
    return this.httpClient.get<IPage>(`${this.urlBase}/slugs/${slug}`);
  }

  getAllPageSlugs(): Observable<string[]> {
    return this.httpClient.get<[]>(`${this.urlBase}/slugs`);
  }
}
